<template>
  <div class="article">
    <div class="article-inner" v-if="this.$store.state.content.currentPOI != null">
      <div class="article-header">
        <router-link :to="'/desktop/'+this.$router.currentRoute.params.area+'/explore'" @click.native="PlaySound">
          <CloseIcon />
        </router-link>
      </div>

      <div class="article-main">
        <h1>{{ this.$store.state.content.currentPOI.Name }}</h1>
        <h2>{{ this.$store.state.content.currentPOI.Subtitle }}</h2>

        <template v-if="this.$store.state.content.currentSubTab != null">
          <div class="article-content">
            <div
              class="subtab-navigation"
              v-if="this.GetVisibleSubTabs().length > 1"
            >
              <div
                class="subtab-link"
                v-for="subTab in this.GetVisibleSubTabs()"
                v-bind:key="subTab.id"
              >
                <IconButton
                  class="subtab"
                  @clicked="SetActiveSubTab(subTab)"
                  :Name="subTab.Subheadline_comp"
                  :active="$store.state.content.currentSubTab == subTab"
                  ><img
                    v-if="subTab.SubTabIcon != null"
                    :src="GetImagePath(subTab.SubTabIcon)"
                /></IconButton>
              </div>
            </div>

            <SubTab />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "../../Components/Buttons/IconButton";
import mainConfig from "../../../main.config";
import CloseIcon from "../../SVG/CloseIcon.svg";
import SubTab from "./SubTab";

export default {
  name: "Article",
  props: {},
  components: {
    IconButton,
    CloseIcon,
    SubTab,
  },
  watch: {
    "$store.state.content.currentPOI": function () {
      this.SetFirstSubTab();
    },
  },
  mounted() {
    if (this.$store.state.content.currentSubTab == null) {
      this.SetFirstSubTab();
    }
  },
  destroyed(){
    console.log("ARTICLE DESTROYED")
  },
  computed: {},
  methods: {
    PlaySound() {
      this.$store.state.audio.controller.PlaySound("menu.click");
    },
    SetActiveSubTab(subTab) {
      this.PlaySound();
      this.$store.dispatch("content/SetSubTab", subTab);
    },
    SetFirstSubTab() {
      if (
        this.$store.state.content.currentPOI != null &&
        this.$store.state.content.currentPOI.SubTabs != null
      ) {
        if (this.$store.state.content.currentPOI.SunPosition != null) {
          this.$store.commit(
            "area/SetSunPosition",
            this.$store.state.content.currentPOI.SunPosition
          );
        } else {
          this.$store.commit("area/SetSunPosition", 0.5);
        }

        if (this.GetVisibleSubTabs().length > 0) {
          this.$store.dispatch("content/SetSubTab", this.GetVisibleSubTabs()[0]);
          return;
        }
      }
      this.$store.dispatch("content/SetSubTab", null);
    },

    GetImagePath(path) {
      return mainConfig.CMS_BASE_URL + path.url;
    },

    CheckTabState(tab) {
      if (!tab.ConfiguratorDependent) {
        return true;
      }
      //Konfigurator anhängig aber den richtigen Konfigurator Einstellungen entsprechen
      var matchingRefs = 0;

      tab.StoreReference.forEach((ref) => {
        var groups = ref.Value.split(".");
        groups = groups.sort().join();

        if (groups.includes(this.$store.state.area[ref.Key]) ) {
          matchingRefs++;
        }
      });
      return matchingRefs == tab.StoreReference.length;
    },

    GetVisibleSubTabs() {
      return this.$store.state.content.currentPOI.SubTabs.filter((tab) => {

        // Einen Subtab komplett ausblenden wenn er CompletelyDisabled ist UND die jeweilige Setting nicht dazu passt
        if(tab.CompletelyDisabled){

          console.log("completelyDisabled", tab.StoreReference);
          return tab.StoreReference.filter(reference => {
            var splitted = reference.Value.split(".");
            return splitted.filter(value => this.$store.state.area[reference.Key] == value).length > 0;
          }).length == tab.StoreReference.length;
        }

        var state = false;
        var visible = this.CheckTabState(tab);
        if (visible) {
          state = true;
        } else {
          state = !tab.CompletelyDisabled;
        }
        return state;
        //Inaktive Tab untscheiden zwischen unsichtbar und ausgegraut
      });
    },
  },
};
</script>