<template>
  <div :class="'season-wheel season-' + this.$store.state.area.season">

    <div class="season-wheel-inner">
      <DashboardSeasonWheel />
    </div>
  </div>
</template>

<script>

import {Season} from '../../Enum/Enums';
import DashboardSeasonWheel from './SeasonWheelIcon';


export default {
  name : "SeasonWheel",
  components: {DashboardSeasonWheel},
  mounted(){
    //console.log("SeasonWheel");
  },
  data(){
    return {
      Season
    }
  },
  methods:{
    ChangeSunPosition(e){
      //console.log(parseFloat(e.target.value));
      this.ChangeSetting("sunPosition",  parseFloat(e.target.value));
    },
    ChangeSetting(setting, value){

      //console.log("value" , value);

      this.$store.commit("area/ChangeGlobalSettings", {
        setting: setting,
        data: value
      });
      c//onsole.log(setting, value);
    }
  }
}
</script>
