<template>
  <main>
    <AnimationPlayer v-if="GetSpecialElement('AnimationPlayer')" />
    <aside
      :class="
        'pointer-all article-aside article-aside-active-' +
        (this.$store.state.content.currentPOI != null)
      "
    >
      <div class="article-tab-wrapper">
        <button v-if="ScrollBarNessesary" @click="e => Scroll(-1)" class="tab-wrapper-button top"><ArrowUp/></button>
        <div class="tab-wrapper-scroll" ref="scroller">
          <ArticleTab
            v-for="poi in GetVisibles()"
            v-bind:key="poi.id"
            :article="poi"
          />
        </div>
        <button v-if="ScrollBarNessesary" @click="e => Scroll(1)" class="tab-wrapper-button bottom"><ArrowDown/></button>
      </div>

      <Article />
    </aside>
  </main>
</template>

<script>
import ArticleTab from "./ArticleTab.vue";
import Article from "./Article.vue";
import UISectionMixin from "../../Mixins/UISectionMixin";
import { Season, ViewMode } from "../../Enum/Enums";
import AnimationPlayer from "../../Components/AnimationPlayer";
import ArrowUp from '@/SVG/Article/ArrowUp.vue';
import ArrowDown from '@/SVG/Article/ArrowDown.vue';

export default {
  name: "Explorer",
  mixins: [UISectionMixin],
  props: {
    Name: {
      type: String,
      default: ViewMode.explorer,
    },
  },
  components: {
    ArticleTab,
    Article,
    AnimationPlayer,
    ArrowUp,
    ArrowDown
  },
  
  data() {
    
    return {
      pageContent: null,
      mounted : false,
      refreshKey : false
    };
  },
  computed:{
    ScrollBarNessesary(){
      if(!this.mounted){return false;}
      var refreshKey = this.refreshKey;

      console.log("resize scrollbarNessesary");

      const visibles = this.GetVisibles();

      console.log(visibles.length * 80 , this.$refs.scroller.offsetHeight);
      
      return visibles.length * 80 > this.$refs.scroller.offsetHeight;
    },
  },
  mounted() {
    this.mounted = true;
    console.log("ExplorerSingle? ", this.$route.name);

    this.pageContent = this.$store.state.content.explorerData;
    this.OnUISectionMounted();
    this.SetPoiToState();

    var pois = this.GetVisibles();

    var poiMarkData = [];

    pois.forEach(
      (poi) => {
        var newObj = {};
        newObj.Name = poi.Name;
        newObj.Route = this.$route.params.area + "/explore/"+poi.id;

        switch(this.$route.params.area){
          case 'familyhouse': newObj.Pos = poi.poi_label_positions;break;
          case 'apartment': newObj.Pos = poi.poi_label_positions_apartment;break;
          case 'apartmentbuilding': newObj.Pos = poi.poi_label_positions_apartment_building;break;
        }
        newObj.Room = 'default';
        newObj.IconName = poi.slug;
        poiMarkData.push(newObj)
      }
    );

    this.$store.state.world.mainScene.sprungMarkenController.SetNewMarks(
      "explorer",
      poiMarkData,
      true
    );



    window.addEventListener("resize", ()=>{
      this.refreshKey = !this.refreshKey;
    });
  },
  methods: {
    
    Scroll(dir){
      if(this.$refs.scroller != undefined){
        this.$refs.scroller.scrollTop += (50 * dir);
      }
    },
    GetSpecialElement(name) {
      if (!this.$route.params.hasOwnProperty("id")) {
        return false;
      }
      if (this.$store.state.content.currentSubTab == null) {
        return false;
      }

      if (this.$store.state.content.currentSubTab.UISpecialElement == null) {
        return false;
      }

      var found = false;
      this.$store.state.content.currentSubTab.UISpecialElement.forEach(
        (element) => {
          if (element.elements == name) {
            found = true;
            return;
          }
        }
      );
      return found;
    },
    CheckTabState(poi) {
      if (!poi.UIBehaviour.ConfiguratorDependent) {
        return true;
      }
      //Konfigurator anhängig aber den richtigen Konfigurator Einstellungen entsprechen
      return (
        poi.UIBehaviour.StoreValue ==
        this.$store.state.area[poi.UIBehaviour.StoreKey]
      );
    },

    GetVisibles() {
      if (this.$store.state.area.current == null) {
        return [];
      }
      var visibles = this.$store.state.content.poi.filter((poi) => {
        if (
          this.CheckTabState(poi) &&
          this.$store.state.area.current.rooms.includes(poi.Room.SceneRoom)
        ) {
          return true;
        }
        if (
          !this.$store.state.area.current.rooms.includes(poi.Room.SceneRoom)
        ) {
          return false;
        }
        //Inaktive Artikel untscheiden zwischen unsichtbar und ausgegraut
        return !poi.UIBehaviour.CompletelyDisabled;
      });

      console.log("visibles" , visibles);

      visibles.sort((a,b) => {
        const aIndex = (a.orderIndex == 0 || a.orderIndex == null) ? -1000 : parseFloat(a.orderIndex);
        const bIndex = (b.orderIndex == 0 || b.orderIndex == null) ? -1000 : parseFloat(b.orderIndex);

        if(aIndex < bIndex){
          return 1;
        }else if(aIndex == bIndex){
          return 1;
        }else{
          return -1;
        }
      });

      return visibles;
    },
    SetPoiToState() {

     
      if (
        this.$route.params.hasOwnProperty("id") &&
        this.$store.state.content.poi != null
      ) {
        let poi = this.$store.state.content.poi.find((poi) => {
          return poi.id == parseInt(this.$route.params.id);
        });

     
        const nextRoom =
          this.$store.state.area.current.rooms[poi.Room.SceneRoom];

        this.$store.state.world.mainScene.xr.SceneController.SetActiveScene(
          poi.Room.SceneRoom
        );
        this.$store.state.world.mainScene.xr.CSSSceneController.SetActiveScene(
          poi.Room.SceneRoom
        );
        this.$store.dispatch("content/SetCurrentPOI", poi);
      } else {
        this.$store.dispatch("content/SetCurrentPOI", null);
       
        this.SetCameraToUISection(this.pageContent);

        //Set To Default Room
        if(this.$store.state.area.current != null){
          this.$store.state.world.mainScene.xr.SceneController.SetActiveScene(
            this.$store.state.area.current.defaultRoom
          );
          this.$store.state.world.mainScene.xr.CSSSceneController.SetActiveScene(
            this.$store.state.area.current.defaultRoom
          );


          console.log("Set To Default and Play Sound");
       
        }

        console.log(
          "%c ChangeToDefault-UI-Section-Position: Explorer",
          "background: #000;color:#fff"
        );
          this.$store.state.world.mainScene.AudioController.PlaySound("season.Summer", true);

        
      }






    },
  },
  watch: {
    "$store.state.content.poi": function () {
      this.SetPoiToState();
    },
    $route: function () {
      this.SetPoiToState();
    },
  },

  destroyed() {
    this.$store.dispatch("content/SetCurrentPOI", null);
  },
};
</script>
