var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"akku-widget"},[_c('div',{staticClass:"akku-image",style:({ 'background-image': 'url(' + _vm.AkkuImage + ')' })},[_c('div',{staticClass:"akku-fill",class:{
          border:
            (
            (this.$store.state.area.current.solarDaten[_vm.GetSolarIndex].Ladezustand) 
          ) > 0.9,
        },style:({
          width:
            (
            (this.$store.state.area.current.solarDaten[_vm.GetSolarIndex].Ladezustand) 
          ) *
              88.5 +
            '%',
        })}),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(( (this.$store.state.area.current.solarDaten[_vm.GetSolarIndex].Ladezustand * 100) ).toFixed(0))+" % ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }