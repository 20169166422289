<template>
  <main>
    <div
      :class="
        'pointer-all card-wrapper flex transparent-' +
          CheckForInline +
          ' was-on-config-' +
          CheckForInline
      "
      v-if="$store.state.area.current != null && pageContent != null"
    >
      <div class="width-3" v-if="!CheckForInline">
        <div class="bg-image">
          <img
            :src="mainConfig.CMS_BASE_URL + pageContent.BackgroundImage.url"
            alt="Hintergrundbild für Konfigurationsseite"
            loading="lazy"
            @load="OnLoadPreviewImage"
          />
        </div>
      </div>

      <div class="width-5 align-self-center">
        <div class="card config-card">
          <h1>{{ pageContent.Title }}</h1>

          <div
            v-if="
              currentPanel == GetPanelIndex('operatorConcept') &&
                this.$store.state.area.current.configSettings.operatorConcept
            "
            class="panel card-row card-margin-bottom card-margin-bottom-large"
          >
            <h2>{{ pageContent.OperatorConceptTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="
                  ChangeSetting(
                    'operatorConcept',
                    OperatorConcept.OnlyOperatorCurrent
                  )
                "
                Name="Betriebsstrom"
                :active="
                  this.$store.state.area.operatorConcept ==
                    OperatorConcept.OnlyOperatorCurrent
                "
                ><BetriebstromIcon
                  :state="
                    this.$store.state.area.operatorConcept ==
                    OperatorConcept.OnlyOperatorCurrent
                      ? 1
                      : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="
                  ChangeSetting(
                    'operatorConcept',
                    OperatorConcept.OperatorCurrentAndCommunity
                  )
                "
                Name="Mieterstrom"
                :active="
                  this.$store.state.area.operatorConcept ==
                    OperatorConcept.OperatorCurrentAndCommunity
                "
                ><MieterstromIcon
                  :state="
                    this.$store.state.area.operatorConcept ==
                    OperatorConcept.OperatorCurrentAndCommunity
                      ? 1
                      : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_OperatorConceptContent }}</p>
            </div>
          </div>

          <!-- <div class="card-row card-margin-bottom">
            <span class="text-bold">{{currentPanel + 1}}</span>/<span class="text-bold">{{GetPanelCount()}}</span>
          </div> -->
          <!--HOUSETYPE-->
          <div
            v-if="
              currentPanel == GetPanelIndex('houseType') &&
                $store.state.area.current.configSettings.houseType
            "
            class="panel card-row card-margin-bottom"
          >
            <h2>{{ pageContent.HouseTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('houseType', HouseType.Sattel)"
                Name="Satteldach"
                :active="this.$store.state.area.houseType == HouseType.Sattel"
                ><Sattel
                  :state="
                    this.$store.state.area.houseType == HouseType.Sattel ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('houseType', HouseType.Zelt)"
                Name="Spitzdach"
                :active="this.$store.state.area.houseType == HouseType.Zelt"
                ><Zelt
                  :state="
                    this.$store.state.area.houseType == HouseType.Zelt ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('houseType', HouseType.Flach)"
                Name="Flachdach"
                :active="this.$store.state.area.houseType == HouseType.Flach"
                :disabled="this.$store.state.area.pvSize == PVSize.PVZiegel"
                ><Flach
                  :state="
                    this.$store.state.area.houseType == HouseType.Flach
                      ? 1
                      : this.$store.state.area.pvSize == PVSize.PVZiegel
                      ? -1
                      : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_HouseContent }}</p>
            </div>
          </div>
          <!--PV-->
          <div
            v-if="
              currentPanel == GetPanelIndex('pv') &&
                this.$store.state.area.current.configSettings.pv
            "
            class="panel card-row card-margin-bottom"
          >
            <h2>{{ pageContent.PVTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('pvSize', PVSize.PVGross)"
                Name="große</br>PV-Anlage"
                :active="this.$store.state.area.pvSize == PVSize.PVGross"
                ><PvGross
                  :state="
                    this.$store.state.area.pvSize == PVSize.PVGross ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('pvSize', PVSize.PVKlein)"
                Name="kleine</br>PV-Anlage"
                :active="this.$store.state.area.pvSize == PVSize.PVKlein"
                ><PvKlein
                  :state="
                    this.$store.state.area.pvSize == PVSize.PVKlein ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('pvSize', PVSize.PVZiegel)"
                Name="PV Ziegel"
                :active="this.$store.state.area.pvSize == PVSize.PVZiegel"
                :disabled="this.$store.state.area.houseType == HouseType.Flach"
                ><PvZiegel
                  :state="
                    this.$store.state.area.pvSize == PVSize.PVZiegel
                      ? 1
                      : this.$store.state.area.houseType == HouseType.Flach
                      ? -1
                      : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_PVContent }}</p>
            </div>
          </div>
          <!--CAR-->
          <div
            v-if="
              currentPanel == GetPanelIndex('car') &&
                this.$store.state.area.current.configSettings.car &&
                ($store.state.area.current.name != Area.ApartmentBuilding
                  ? true
                  : $store.state.area.operatorConcept !=
                    OperatorConcept.OnlyOperatorCurrent)
            "
            class="panel card-row card-margin-bottom"
          >
            <h2>{{ pageContent.CarTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('car', Car.Car)"
                Name="E-Auto"
                :active="this.$store.state.area.car == Car.Car"
                ><ECar :state="this.$store.state.area.car == Car.Car ? 1 : 0"
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('car', Car.NoCar)"
                Name="Kein E-Auto"
                :active="this.$store.state.area.car == Car.NoCar"
                ><NoECar
                  :state="this.$store.state.area.car == Car.NoCar ? 1 : 0"
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_CarContent }}</p>
            </div>
          </div>
          <!--SHADOW-->
          <div
            v-if="
              currentPanel == GetPanelIndex('shadow') &&
                this.$store.state.area.current.configSettings.shadow
            "
            class="panel card-row card-margin-bottom"
          >
            <h2>{{ pageContent.ShadowTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('shadow', Shadow.Shadow)"
                Name="Verschattung"
                :active="this.$store.state.area.shadow == Shadow.Shadow"
                ><ShadowIcon
                  :state="
                    this.$store.state.area.shadow == Shadow.Shadow ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('shadow', Shadow.NoShadow)"
                Name="keine / wenig Verschattung"
                :active="this.$store.state.area.shadow == Shadow.NoShadow"
                ><NoShadowIcon
                  :state="
                    this.$store.state.area.shadow == Shadow.NoShadow ? 1 : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_ShadowContent }}</p>
            </div>
          </div>
          <!--PUMP-->
          <div
            v-if="
              currentPanel == GetPanelIndex('pump') &&
                this.$store.state.area.current.configSettings.pump
            "
            class="panel card-row card-margin-bottom card-margin-bottom-large"
          >
            <h2>{{ pageContent.PumpTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('pump', Pump.Pump)"
                Name="Wärmepumpe"
                :active="this.$store.state.area.pump == Pump.Pump"
                ><PumpIcon
                  :state="this.$store.state.area.pump == Pump.Pump ? 1 : 0"
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('pump', Pump.NoPump)"
                Name="keine Wärmepumpe"
                :active="this.$store.state.area.pump == Pump.NoPump"
                ><NoPump
                  :state="this.$store.state.area.pump == Pump.NoPump ? 1 : 0"
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_PumpContent }}</p>
            </div>
          </div>
          <!--GREEN ROOF-->
          <div
            v-if="
              currentPanel == GetPanelIndex('greenRoof') &&
                this.$store.state.area.current.configSettings.greenRoof
            "
            class="panel card-row card-margin-bottom card-margin-bottom-large"
          >
            <h2>{{ pageContent.GreenRoofTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('greenRoof', GreenRoof.GreenRoof)"
                Name="Dachbegrünung"
                :active="
                  this.$store.state.area.greenRoof == GreenRoof.GreenRoof
                "
                ><GreenRoofIcon
                  :state="
                    this.$store.state.area.greenRoof == GreenRoof.GreenRoof
                      ? 1
                      : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('greenRoof', GreenRoof.NoGreenRoof)"
                Name="keine Dachbegrünung"
                :active="
                  this.$store.state.area.greenRoof == GreenRoof.NoGreenRoof
                "
                ><NoGreenRoofIcon
                  :state="
                    this.$store.state.area.greenRoof == GreenRoof.NoGreenRoof
                      ? 1
                      : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_GreenRoofContent }}</p>
            </div>
          </div>
          <!--BATTERy-->
          <div
            v-if="
              currentPanel == GetPanelIndex('battery') &&
                this.$store.state.area.current.configSettings.battery
            "
            class="panel card-row card-margin-bottom card-margin-bottom-large"
          >
            <h2>{{ pageContent.BatteryTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('battery', Battery.Battery)"
                Name="Stromspeicher"
                :active="this.$store.state.area.battery == Battery.Battery"
                ><BatteryIcon
                  :state="
                    this.$store.state.area.battery == Battery.Battery ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('battery', Battery.NoBattery)"
                Name="keine Stromspeicher"
                :active="this.$store.state.area.battery == Battery.NoBattery"
                ><NoBatteryIcon
                  :state="
                    this.$store.state.area.battery == Battery.NoBattery ? 1 : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_BatteryContent }}</p>
            </div>
          </div>
          <!--FASCADE-->
          <div
            v-if="
              currentPanel == GetPanelIndex('fascade') &&
                this.$store.state.area.current.configSettings.fascade
            "
            class="panel card-row card-margin-bottom card-margin-bottom-large"
          >
            <h2>{{ pageContent.FascadeTitle }}</h2>
            <div class="flex flex-align-horizontal-center">
              <IconButton
                @clicked="ChangeSetting('fascade', Fascade.Fascade)"
                Name="Fassadenmodule"
                :active="this.$store.state.area.fascade == Fascade.Fascade"
                ><FascadeIcon
                  :state="
                    this.$store.state.area.fascade == Fascade.Fascade ? 1 : 0
                  "
              /></IconButton>
              <IconButton
                @clicked="ChangeSetting('fascade', Fascade.NoFascade)"
                Name="keine Fassadenmodule"
                :active="this.$store.state.area.fascade == Fascade.NoFascade"
                ><NoFascadeIcon
                  :state="
                    this.$store.state.area.fascade == Fascade.NoFascade ? 1 : 0
                  "
              /></IconButton>
            </div>
            <div class="config-content">
              <p>{{ pageContent.Config_FascadeContent }}</p>
            </div>
          </div>

          <div
            class="card-row flex flex-between flex-align-vertical-center card-margin-bottom"
          >
            <button
              class="panel-navigation-button no-wait"
              @click="(e) => ChangeCurrentPanel(-1)"
              :disabled="currentPanel == 0"
            >
              <ArrowLeft :disabled="currentPanel == 0"/>
            </button>
            <div
              class="card-row flex flex-align-horizontal-center"
            >
              <button
                @click="(e) => SetCurrentPanel(settingIndex - 1)"
                class="panelPicker"
                :class="{ active: settingIndex - 1 == currentPanel }"
                v-for="settingIndex in GetPanelCount()"
                v-bind:key="settingIndex"
              >
                <span class="hidden">{{ settingIndex }}</span>
              </button>
            </div>
            <button
              class="panel-navigation-button"
              :disabled="currentPanel == GetPanelCount() - 1"
              @click="(e) => ChangeCurrentPanel(1)"
            >
              <ArrowRight :disabled="currentPanel == GetPanelCount() - 1"/>
            </button>
          </div>

          <router-link
            v-if="currentPanel == GetPanelCount() - 1 && GetProgress() == 1"
            class="cta-button button dark"
            @click.native="e=> {if(GetProgress() < 1){
              e.preventDefault(e);return;}
              PlayClick();
              $store.state.audio.controller.EnableSounds()
              }"
            :to="
              '/desktop/' + this.$router.currentRoute.params.area + '/dashboard'
            "
            >Jetzt 3D Simulation starten</router-link
          >

          <span
            v-if="currentPanel == GetPanelCount() - 1 && GetProgress() < 1"
            class="cta-button button dark"
            ><div
              class="progress-bar"
              :style="{ width: GetProgress() * 100 + '%' }"
            ></div>
            <span class="progress-on-top">{{
              GetProgress() < 1
                ? (GetProgress() * 100).toFixed(0) + "/100"
                : "Jetzt 3D Simulation starten"
            }}</span></span
          >



        </div>
      </div>

      <div class="width-4 align-self-center" v-if="!CheckForInline">
        <div class="card-no-style">
          <div v-if="!CheckForInline" class="card-orientation">
            <h3>Vorschau</h3>
            <div class="preview-thumb">
              <img
                :src="GetPreviewImage"
                :alt="GetPreviewImageAlt"
                loading="lazy"
                @load="OnLoadPreviewImage"
              />
              <div class="loading-icon-container" v-if="this.imageLoading">
                <Reload class="loading-icon" />
              </div>
            </div>

            <!-- <div class="card-row">
              <h2>{{ pageContent.OrientationTitle }}</h2>
            </div> -->
          </div>

          <div class="card-wrapper">
            <div class="card-no-style">
              <VueMarkdown class="config-description" :source="$store.state.area.current.name == 'ApartmentBuilding' ? pageContent.ConfigDescription_ApartmentBuilding : pageContent.ConfigDescription"></VueMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import IOMixins from "../../Mixins/IOMixins";
import mainConfig from "../../../main.config";
import IconButton from "../../Components/Buttons/IconButton.vue";
import OrientationButtons from "../../SVG/Configurator/Orientations_UI";
import Reload from "../../SVG/reload.svg";

import {
  Area,
  HouseType,
  PVSize,
  Shadow,
  Orientation,
  Car,
  Pump,
  GreenRoof,
  Battery,
  Fascade,
  ViewMode,
  OperatorConcept,
} from "../../Enum/Enums.ts";

import Sattel from "../../SVG/Configurator/DGSZentrum_IconSet_Sattel_Icon.vue";
import Zelt from "../../SVG/Configurator/DGSZentrum_IconSet_Zelt_Icon.vue";
import Flach from "../../SVG/Configurator/DGSZentrum_IconSet_Flach_Icon.vue";

import PvGross from "../../SVG/Configurator/DGSZentrum_IconSet_PvGross_Icon.vue";
import PvKlein from "../../SVG/Configurator/DGSZentrum_IconSet_PvKlein_Icon.vue";
import PvZiegel from "../../SVG/Configurator/DGSZentrum_IconSet_PvZiegel_Icon.vue";

import ECar from "../../SVG/Configurator/DGSZentrum_IconSet_EAutoTrue_Icon.vue";
import NoECar from "../../SVG/Configurator/DGSZentrum_IconSet_EAutoFalse_Icon.vue";

import ShadowIcon from "../../SVG/Configurator/DGSZentrum_IconSet_Shadow_Icon.vue";
import NoShadowIcon from "../../SVG/Configurator/DGSZentrum_IconSet_NoShadow_Icon.vue";

import PumpIcon from "../../SVG/Configurator/DGSZentrum_IconSet_PumpTrue.vue";
import NoPump from "../../SVG/Configurator/DGSZentrum_IconSet_PumpFalse.vue";

import FascadeIcon from "../../SVG/Configurator/DGSZentrum_Iconset_Fascade_Icon.vue";
import NoFascadeIcon from "../../SVG/Configurator/DGSZentrum_Iconset_NoFascade_Icon.vue";

import GreenRoofIcon from "../../SVG/Configurator/DGSZentrum_Iconset_GreenRoof_Icon.vue";
import NoGreenRoofIcon from "../../SVG/Configurator/DGSZentrum_Iconset_NoGreenRoof_Icon.vue";

import BetriebstromIcon from "../../SVG/Article/DGSZentrum_IconSet_Betriebsstrom_Icon.vue";
import MieterstromIcon from "../../SVG/Article/DGSZentrum_IconSet_Mieterstrom_Icon.vue";

import UISectionMixin from "../../Mixins/UISectionMixin";
import LoadMixin from "../../Mixins/LoadMixin";

import ArrowLeft from "@/SVG/Configurator/Arrow_left.vue";
import ArrowRight from "@/SVG/Configurator/Arrow_right.vue";
import BatteryIcon from '@/SVG/Configurator/DGSZentrum_IconSet_Battery_Icon.vue';
import NoBatteryIcon from '@/SVG/Configurator/DGSZentrum_IconSet_NoBattery_Icon.vue';
import VueMarkdown from '@/Components/vue-markdown.vue';


export default {
  components: {
    IconButton,
    Sattel,
    Zelt,
    Flach,
    PvGross,
    PvKlein,
    PvZiegel,
    ECar,
    NoECar,
    ShadowIcon,
    NoShadowIcon,
    PumpIcon,
    NoPump,
    FascadeIcon,
    NoFascadeIcon,
    GreenRoofIcon,
    NoGreenRoofIcon,
    BetriebstromIcon,
    MieterstromIcon,
    OrientationButtons,
    Reload,
    ArrowLeft,
    ArrowRight,
    BatteryIcon,
    NoBatteryIcon,
    VueMarkdown
  },
  props: {
    Name: {
      type: String,
      default: ViewMode.configurator,
    },
  },

  mixins: [UISectionMixin, LoadMixin],
  data() {
    return {
      Area,
      HouseType,
      PVSize,
      Shadow,
      Orientation,
      Car,
      Pump,
      GreenRoof,
      Fascade,
      Battery,
      ViewMode,
      OperatorConcept,
      mainConfig,
      pageContent: null,
      prevRoute: null,
      from: null,
      imageLoading: false,
      IsInline: false,
      currentPanel: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  },
  beforeDestroy() {
    document.body.classList.remove("configurator-overlay");
    document.body.classList.remove("configurator-inline");
  },
  computed: {
    CheckForInline() {
      const roomsLoading = this.$store.state.area.areas[
        this.$route.params.area
      ].rooms.filter((roomName) => {
        return this.$store.state.world.mainScene.rooms[roomName].isLoading;
      });

      if (roomsLoading.length == 0 && this.IsInline) {
        document.body.classList.remove("configurator-overlay");
        document.body.classList.add("configurator-inline");
      } else {
        document.body.classList.remove("configurator-inline");
        document.body.classList.add("configurator-overlay");
      }

      return roomsLoading.length == 0 && this.IsInline;
    },

    GetPreviewImage: function() {
      if (this.pageContent === null) {
        return;
      }

      var keyName = this.GetScreenshotName();

      const imageType = this.$store.state.area.current.name == "ApartmentBuilding" ? "Screenshots_ApartmentBuilding" : "Screenshots";

      console.log(imageType, this.pageContent);

      var previewItem = this.pageContent[imageType].find(
        (previewImage) => previewImage.Name === keyName
      );

      if (
        typeof previewItem != "undefined" &&
        previewItem.Screenshot_image != null
      ) {
        return mainConfig.CMS_BASE_URL + previewItem.Screenshot_image.url;
      } else {
        return null;
      }
    },
    GetPreviewImageAlt: function() {
      if (this.pageContent === null) {
        return;
      }
      var keyName = this.GetScreenshotName();
      var previewItem = this.pageContent.Screenshots.find(
        (previewImage) => previewImage.Name === keyName
      );
      if (
        typeof previewItem != "undefined" &&
        previewItem.Screenshot_image != null
      ) {
        return previewItem.Screenshot_image.alternativeText;
      } else {
        return null;
      }
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.IsInline = this.GetProgress() == 1;
    });
  },
  mounted() {

    if(this.$store.state.xrMode == "desktop"){
      this.LoadInline();
    }

    this.pageContent = this.$store.state.content.configData;
    this.OnUISectionMounted();

    if (!this.$store.state.world.sceneReady) {
      this.$store.commit("area/SetClockSolarDaten", true);
    }

    if (this.$store.state.audio.controller != null) {
      this.$store.state.audio.controller.StopAll();
    }
  },
  methods: {
    GetPanelCount() {
      if (this.$store.state.area.current == null) {
        return 0;
      }
      var count = Object.keys(
        this.$store.state.area.current.configSettings
      ).filter(
        (setting) => this.$store.state.area.current.configSettings[setting]
      ).length;

      //Exclude das Auto
      if (
        this.$store.state.area.current.name == Area.ApartmentBuilding &&
        this.$store.state.area.operatorConcept ==
          OperatorConcept.OnlyOperatorCurrent
      ) {
        count -= 1;
      }

      return count;
    },
    SetCurrentPanel(index) {
      this.currentPanel = index;
    },
    ChangeCurrentPanel(dir) {
      if (dir > 0 && this.currentPanel + 1 == this.GetPanelCount()) {
        return;
      }

      this.currentPanel += dir;
    },
    GetPanelIndex(type) {
      if (this.$store.state.area.current == null) {
        return 0;
      }
      let settings = Object.keys(
        this.$store.state.area.current.configSettings
      ).filter(
        (setting) => this.$store.state.area.current.configSettings[setting]
      );

      //Exclude das Auto
      if (
        this.$store.state.area.current.name == Area.ApartmentBuilding &&
        this.$store.state.area.operatorConcept ==
          OperatorConcept.OnlyOperatorCurrent
      ) {
        settings = settings.filter((setting) => setting != "car");
      }

      return settings.indexOf(type);
    },
    OnLoadPreviewImage() {
      this.imageLoading = false;
      //console.log("image is loaded ", this.imagesToLoad);
    },
    GetScreenshotName() {
      var s = this.$store.state.area;

      if(this.$store.state.area.current.name == "ApartmentBuilding"){
        return `operatorConcept_${s.operatorConcept}__car_${s.car}__pump_${s.pump}__fascade_${s.fascade}__greenRoof_${s.greenRoof}__battery_${s.battery}`;
      }else{
        return `houseType_${s.houseType}__pvSize_${s.pvSize}__car_${s.car}__shadow_${s.shadow}__pump_${s.pump}`;
      }
      //houseType_${s.houseType}__pvSize_${s.pvSize}__car_${s.car}__shadow_${s.shadow}__pump_${s.pump}

    },
    GetSettings() {
      return (
        this.$store.state.area.houseType +
        "_" +
        this.$store.state.area.pvSize +
        "_" +
        this.$store.state.area.shadow +
        "_" +
        this.$store.state.area.orientation
      );
    },
    ChangeSetting(setting, value) {

      var oldSettings = this.GetScreenshotName();
      

      this.$store.state.audio.controller.PlaySound("menu.click");
      this.$store.commit("area/ChangeGlobalSettings", {
        setting: setting,
        data: value,
      });

      var newSettings = this.GetScreenshotName();

      this.imageLoading = oldSettings != newSettings;
    },
    GoBack() {
      this.$store.state.audio.controller.PlaySound("menu.click");

      var potentials = [
        "Home",
        "Landing",
        "Explorer",
        "ExplorerSingle",
        "Configurator",
        "Dashboard",
      ];
      if (this.from != null && potentials.includes(this.from.name)) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    PlayClick() {
      this.$store.state.world.mainScene.xr.Renderer.instance.shadowMap.needsUpdate = true;
      this.$store.state.audio.controller.PlaySound("menu.click");
    },
  },
};
</script>

<style scoped lang="scss">
.config-card {
  padding: 2rem;
  background: #f8f8f8;
  box-shadow: 0px 0px 34px rgba(46, 49, 63, 0.35);
  border-radius: 20px;
  max-width: 500px;
  margin: 2rem auto;
}

.was-on-config-false .card-wrapper .width-3,
.was-on-config-false .card-wrapper .width-4,
.was-on-config-false .card-wrapper .width-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.was-on-config-true {
  right: 0;
  position: absolute;
  height: 100%;
  width: 30%;
  min-width: 400px;

  .width-5 {
    width: 100%;
    height: 100%;
  }
  .card {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    justify-content: unset;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 20px 0 0 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.cta-button.dark{
  background: #303448;
  *{color:#fff;}
}

h1 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 1rem;
}
.card-wrapper {
  justify-content: center;
  h2 {
    font-size: 1rem;
    color: $orange;
    text-align: center;
  }
  &.transparent-true {
    background: none;
  }
  &.transparent-false {
    background: #e5e5e5;
  }
}

.icon-config-button {
  margin: 0 0.5rem;
}

.bg-image {
  position: absolute;
  bottom: 0;
  width: calc(100% + 5rem);
  z-index: 1;
  pointer-events: none;
  left: 0;
}

.config-content {
  text-align: center;
  margin: 1rem auto 0 auto;

  p {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
  }
}

//////////////Navigation

.panel-navigation-button {
  width: 50px;
  height: 50px;

  svg{
    width: 20px;
  }

  &:hover{
    opacity: 1;
  }

  &[disabled]{
    cursor: not-allowed;
  }
}


//////////////@at-root

.card-orientation {
  h2,
  h3 {
    width: 80%;
    text-align: center;
    margin: auto;
    position: relative;
  }

  h3 {
    margin-bottom: 1rem;
  }
}
.loading-icon-container {
  background-color: rgba(100, 100, 100, 0.4);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.loading-icon {
  width: 20%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.preview-thumb {
  width: 100%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  img {
    display: block;
    position: absolute;
    top: 0;
  }
}

.orientation-controller {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  margin-top: -12%;
  margin-bottom: 1rem;
  position: relative;
}

.config-description {
  padding: 1rem;
  background: #eee;
  border-radius: 15px;
  text-align: center;
  margin-top: 1rem;
}

.card-row .cta-button-small {
  min-width: 30%;
  position: relative;
  overflow: hidden;

  .progress-bar {
    position: absolute;
    background: $orange;
    background: linear-gradient(135deg, #f1832c 0%, #fc9e31 100%);
    height: 100%;
    width: 10%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .progress-on-top {
    position: relative;
    z-index: 9;
    color: #fff;
  }

  &[disabled] {
    background: #eee;
    color: #aaa;
    cursor: wait;
  }
  &.no-wait[disabled] {
    cursor: not-allowed;
  }
}

.panelPicker {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-right: 5px;
  background: #ccc;
  &.active {
    background: $orange;
  }
}
.panel {
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  background: #fff;
  border-radius: 10px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

</style>
<style lang="scss">
.config-description p {
  color: #616161;

  &:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1000px) {
  .subtab.icon-config-button.icon-config-button button {
    height: 70px;
  }
}
</style>
