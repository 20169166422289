<template>
  <div class="article-enable" v-if="!this.$store.state.content.currentSubTab.CompletelyDisabled">
    <template
      
      v-for="reference in this.$store.state.content.currentSubTab.StoreBooleanSettings"
    >
      <div class="article-setting" :key="reference.id">
        <input
          :checked="!$store.state.area[reference.Key].includes('No')"
          :id="'setting-' + reference.Key"
          type="checkbox"
          @change="(e) => EnableTabSetting(reference, e.target.checked)"
        />
        <label
          :checked="!$store.state.area[reference.Key].includes('No')"
          :for="'setting-' + reference.Key"
          ><VueMarkdown
            v-if="
              $store.state.content.currentPOI.UIBehaviour.DefaultText != null &&
              $store.state.area[reference.Key].includes('No')
            "
            :source="
              $store.state.content.currentPOI.UIBehaviour.DefaultText
            " />
            <VueMarkdown
            v-if="
              $store.state.content.currentPOI.UIBehaviour.DefaultTextActive != null &&
              !$store.state.area[reference.Key].includes('No')
            "
            :source="$store.state.content.currentPOI.UIBehaviour.DefaultTextActive"
        /></label>
      </div>
    </template>
  </div>
</template>

<script>
import VueMarkdown from '@/Components/vue-markdown.vue';
export default {
  name: "ToggleSubTab",
  components:{VueMarkdown},
  methods: {
    EnableTabSetting(reference, boolean) {
      var { Key, Value } = reference;

      this.$store.commit("area/ChangeGlobalSettings", {
        setting: Key,
        data: boolean ? Value : "No" + Value,
        boolean: boolean,
      });

      this.$store.state.audio.controller.PlaySound("menu.click");
    },
  },
};
</script>