<template>
  <div class="akku-widget">
      <div
        class="akku-image"
        :style="{ 'background-image': 'url(' + AkkuImage + ')' }"
      >
        <div
          class="akku-fill"
          :class="{
            border:
              (
              (this.$store.state.area.current.solarDaten[GetSolarIndex].Ladezustand) 
            ) > 0.9,
          }"
          :style="{
            width:
              (
              (this.$store.state.area.current.solarDaten[GetSolarIndex].Ladezustand) 
            ) *
                88.5 +
              '%',
          }"
        ></div>
        <div class="label">
          {{
            (
              (this.$store.state.area.current.solarDaten[GetSolarIndex].Ladezustand * 100)
            ).toFixed(0)
          }}
          %
        </div>
      </div>
  </div>
</template>
<script>
import SolarUtils from "../../Mixins/SolarUtils";
import AkkuImage from "../../SVG/Dashboard/akku_bg.png";

export default {
  name: "AkkuWidget",
  mixins: [SolarUtils],
  data() {
    return {
      AkkuImage: AkkuImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.akku-widget {
  position: relative;
  margin: 1rem auto 0 auto;
  &:after {
    content: "";
    display: block;
    padding-top: 48%;
  }
}

.controls-disable {
  z-index: 9;
  position: absolute;
  top: -1rem;

  margin: auto;
  width: 40px;
  height: 40px;
  left: 0px;
  right: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    font-size: 36px;
    line-height: 0;
  }
}

.controls-enable {
  z-index: 9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;

  label {
    font-size: 80%;
    display: block;
    width: 70%;
    line-height: 1rem;
    text-align: center;
    margin: auto;
    color: #303448;
    cursor: pointer;
  }

  button {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-top: 0.8rem;
    margin-bottom: 0.25rem;
    border-radius: 50%;
  }
}

.akku-image {
  height: 100%;
  width: 100%;
  min-width: 95px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  &:before {
    content: "";
    display: block;
    padding-top: 51%;
  }

  .label {
    pointer-events: none;
    position: absolute;
    color: $orange;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  }
}

.akku-fill {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 3.6%;
  top: 0;
  width: 100%;
  height: 97.5%;
  margin: auto;
  background: #f9c697;
  //background:linear-gradient(0deg, $orange, #F9C697,$orange);
  border-radius: 9%/16%;
}
</style>
