<template>
  <div class="page-wrapper">
    <div class="card-board-horizontal" v-if="$store.state.area.current != null">
      <!--SEASON WHEEL-->
      <div class="card-inner card-margin-right">
        <div class="card height-12 width-12-s fixed-card-small">
          <h2>Alle Daten im Überblick</h2>
          <div class="width-12">
            <div class="row border-bottom">
              <div class="flex">
                <div class="card-info-row special width-6">
                  <h3>Größe der <span class="no-break orange">PV-Anlage</span></h3>
                  <template
                    v-if="
                      $store.state.area.current.name == Area.FamilyHouse &&
                        ($store.state.area.pvSize == PVSize.PVGross ||
                          $store.state.area.pvSize == PVSize.PVZiegel)
                    "
                  >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Sattel"
                      >8,4 kW</span
                    >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Zelt"
                      >7,5 kW</span
                    >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Flach"
                      >7,3 kW</span
                    >
                  </template>
                  <template
                    v-if="
                      $store.state.area.current.name == Area.FamilyHouse &&
                        $store.state.area.pvSize == PVSize.PVKlein
                    "
                  >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Sattel"
                      >4,4 kW</span
                    >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Zelt"
                      >5 kW</span
                    >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.houseType == HouseType.Flach"
                      >4,0 kW</span
                    >
                  </template>

                  <template
                    v-if="
                      $store.state.area.current.name == Area.ApartmentBuilding
                    "
                  >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.fascade == Fascade.Fascade"
                      >56,24 kWp</span
                    >
                    <span
                      class="no-wrap"
                      v-if="$store.state.area.fascade == Fascade.NoFascade"
                      >26,64 kWp</span
                    >
                  </template>
                </div>
                <div class="card-info-row special">
                  <h3>Ertrag pro Tag</h3>
                  <span class="no-wrap">~ {{ GetGesamtertrag }} kWh</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="flex">
                <div class="width-6">
                  <div class="card-info-row special">
                    <h3>Aktueller Ertrag</h3>
                    <span class="no-wrap"
                      >{{ GetEnergieWechselrichter }} kWh</span
                    >
                  </div>
                </div>

                <div
                  class="card-info-row special width-6"
                  v-if="pageContent != null && $store.state.area.battery == Battery.Battery"
                >
                  <h3 v-html="pageContent.AkkuTitle" />
                  <span class="no-wrap" v-if="$route.params.area == 'familyhouse'">5 kWh</span>
                  <span class="no-wrap" v-if="$route.params.area == 'apartmentbuilding'">26,2 kWh</span>
                  <!-- <vue-markdown>{{ pageContent.AkkuDescription }}</vue-markdown> -->
                  <AkkuWidget />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--BILANZ-->
      <div
        class="card width-10 width-12-s width-12-m"
        v-if="pageContent != null"
      >
        <div v-if="!$store.state.area.showSubBilanz">
          <h2 v-html="pageContent.BilanzTitle" />
          <VueMarkdown :source="pageContent.BilanzDescription"></VueMarkdown>
        </div>
        <DayBilanz />
        <h5 class="subtitle">in kWh</h5>
        <div class="legende">
          <h5 class="item">
            <div class="einkauf dot" />
            Stromeinkauf
          </h5>
          <h5 class="item">
            <div class="nutzung dot" />
            Stromnutzung
          </h5>
          <h5 class="item">
            <div class="ueberschuss dot" />
            Stromüberschuss
          </h5>
        </div>
      </div>
    </div>

    <div class="card-board pointer-all" v-if="pageContent != null">
      <div class="card-board-inner" v-if="$store.state.area.current != null">
        <slot />
        <!--CONFIG-->
        <div class="card width-12-s">
          <h2>
            <span class="no-wrap">Schnellkonfiguration &</span> <span class="no-wrap">Jahres- und Tageszeiten</span>
          </h2>

          <SeasonWheel />
          <div class="align-center text-bold">{{Season[$store.state.area.season]}} | {{TimeShort[GetSolarIndex]}} <br/> {{Time[GetSolarIndex]}}</div>

          <div class="global-settings">
            <div class="" v-if="$route.params.area == 'familyhouse'">
              <div class="card-info-row special">
                <h3>Hausart bzw. Dachart</h3>
                <RadioButton
                  @onChange="
                    (option) =>
                      $store.commit('area/ChangeGlobalSettings', {
                        setting: 'houseType',
                        data: option.value,
                        boolean: null,
                      })
                  "
                  :selected="$store.state.area.houseType"
                  :options="[
                    {
                      name: 'Zelt',
                      value: HouseType.Zelt,
                      icon: 'Zelt',
                      disabled: false,
                    },
                    {
                      name: 'Sattel',
                      value: HouseType.Sattel,
                      icon: 'Sattel',
                      disabled: false,
                    },
                    {
                      name: 'Flach',
                      value: HouseType.Flach,
                      icon: 'Flach',
                      disabled: $store.state.area.pvSize == PVSize.PVZiegel,
                    },
                  ]"
                />
                <Checkbox
                  @onChange="
                    (val) =>
                      $store.commit('area/ChangeGlobalSettings', {
                        setting: 'pvSize',
                        data: val ? PVSize.PVZiegel : PVSize.PVGross,
                        boolean: null,
                      })
                  "
                  :disabled="$store.state.area.houseType == HouseType.Flach"
                  :checked="$store.state.area.pvSize == PVSize.PVZiegel"
                  title="Solarziegel"
                />
              </div>

              <div class="card-info-row special">
                <h3>Größe der PV-Anlage</h3>
                <LeftRightCheckbox
                  :disabled="$store.state.area.pvSize == PVSize.PVZiegel"
                  @onChange="
                    (val) =>
                      $store.commit('area/ChangeGlobalSettings', {
                        setting: 'pvSize',
                        data: val ? PVSize.PVGross : PVSize.PVKlein,
                        boolean: null,
                      })
                  "
                  :checked="$store.state.area.pvSize == PVSize.PVGross"
                  title="Gross"
                  :leftTitle="'Klein'"
                />
              </div>
            </div>

            <div class="" v-if="$route.params.area == 'apartmentbuilding'">
              <div class="card-info-row special">
                <h3>Betreiberkonzept</h3>
                <div class="">
                  <div class="radio-item">
                    <input
                      id="betreiber_only"
                      @change="
                        (e) =>
                          $store.commit('area/ChangeGlobalSettings', {
                            setting: 'operatorConcept',
                            data: e.target.value
                              ? OperatorConcept.OnlyOperatorCurrent
                              : OperatorConcept.OperatorCurrentAndCommunity,
                            boolean: e.target.value,
                          })
                      "
                      type="radio"
                      name="betreiber"
                      :checked="
                        $store.state.area.operatorConcept ==
                          OperatorConcept.OnlyOperatorCurrent
                      "
                    />
                    <label for="betreiber_only">nur Betriebsstrom</label>
                  </div>
                  <div class="radio-item">
                    <input
                      id="betreiber_mixed"
                      @change="
                        (e) =>
                          $store.commit('area/ChangeGlobalSettings', {
                            setting: 'operatorConcept',
                            data: e.target.value
                              ? OperatorConcept.OperatorCurrentAndCommunity
                              : OperatorConcept.OnlyOperatorCurrent,
                            boolean: e.target.value,
                          })
                      "
                      type="radio"
                      name="betreiber"
                      :checked="
                        $store.state.area.operatorConcept ==
                          OperatorConcept.OperatorCurrentAndCommunity
                      "
                    />
                    <label for="betreiber_mixed">Wohnungsstrom</label>
                  </div>
                </div>
              </div>
              <div
                class="card-info-row special"
                :class="{
                  disabled:
                    $store.state.area.operatorConcept !=
                    OperatorConcept.OperatorCurrentAndCommunity,
                }"
              >
                <h3
                  :class="{
                    disabled:
                      $store.state.area.operatorConcept !=
                      OperatorConcept.OperatorCurrentAndCommunity,
                  }"
                >
                  Beteiligungsquote
                </h3>
                <input
                  type="range"
                  min="0.5"
                  max="1"
                  step="0.25"
                  :disabled="
                    $store.state.area.operatorConcept !=
                      OperatorConcept.OperatorCurrentAndCommunity
                  "
                  class="range-slider-style no-prevention"
                  :value="$store.state.area.participationRate"
                  @input="
                    (e) =>$store.commit('area/ChangeGlobalSettings', {
                        setting: 'participationRate',
                        data: parseFloat(e.target.value),
                      })
                  "
                />
                <div class="flex flex-between quote">
                  <label
                    :class="{
                      disabled:
                        $store.state.area.operatorConcept !=
                        OperatorConcept.OperatorCurrentAndCommunity,
                      selected: $store.state.area.participationRate == 0.5,
                    }"
                    >50%</label
                  >
                  <label
                    :class="{
                      disabled:
                        $store.state.area.operatorConcept !=
                        OperatorConcept.OperatorCurrentAndCommunity,
                      selected: $store.state.area.participationRate == 0.75,
                    }"
                    >75%</label
                  >
                  <label
                    :class="{
                      disabled:
                        $store.state.area.operatorConcept !=
                        OperatorConcept.OperatorCurrentAndCommunity,
                      selected: $store.state.area.participationRate == 1,
                    }"
                    >100%</label
                  >
                </div>
              </div>
            </div>

            <Checkbox
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'battery',
                    data: val ? Battery.Battery : Battery.NoBattery,
                    boolean: val,
                  })
              "
              :checked="$store.state.area.battery == Battery.Battery"
              title="PV-Speicher"
            />
            <Checkbox
              v-if="
                $store.state.area.current.name != Area.ApartmentBuilding ? true : 
                ($store.state.area.operatorConcept !=
                  OperatorConcept.OnlyOperatorCurrent)
              "
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'car',
                    data: val ? Car.Car : Car.NoCar,
                    boolean: val,
                  })
              "
              :checked="$store.state.area.car == Car.Car"
              title="E-Auto"
            />
            <Checkbox
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'pump',
                    data: val ? Pump.Pump : Pump.NoPump,
                    boolean: val,
                  })
              "
              :checked="$store.state.area.pump == Pump.Pump"
              title="Wärmepumpe"
            />
            <Checkbox
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'shadow',
                    data: val ? Shadow.Shadow : Shadow.NoShadow,
                    boolean: val,
                  })
              "
              v-if="$route.params.area == 'familyhouse'"
              :checked="$store.state.area.shadow == Shadow.Shadow"
              title="Verschattung"
            />

            <Checkbox
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'fascade',
                    data: val ? Fascade.Fascade : Fascade.NoFascade,
                    boolean: val,
                  })
              "
              v-if="$route.params.area == 'apartmentbuilding'"
              :checked="$store.state.area.fascade == Fascade.Fascade"
              title="Fassadenmodule"
            />

            <Checkbox
              @onChange="
                (val) =>
                  $store.commit('area/ChangeGlobalSettings', {
                    setting: 'greenRoof',
                    data: val ? GreenRoof.GreenRoof : GreenRoof.NoGreenRoof,
                    boolean: val,
                  })
              "
              v-if="$route.params.area == 'apartmentbuilding'"
              :checked="$store.state.area.greenRoof == GreenRoof.GreenRoof"
              title="Dachbegrünung"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UISectionMixin from "../../Mixins/UISectionMixin";
import { TimeShort, Time, Season, ViewMode, Area, Battery } from "../../Enum/Enums";
//import Bilanz from "./Bilanz";
import DayBilanz from "./DayBilanz";
import SeasonWheel from "./SeasonWheel.vue";
import AkkuWidget from "./AkkuWidget.vue";
import Utility from "../../utils";

import Checkbox from "../../Components/Buttons/Checkbox.vue";

import {
  Car,
  Pump,
  Shadow,
  Fascade,
  PVSize,
  HouseType,
  GreenRoof,
  OperatorConcept,
} from "../../Enum/Enums";
import RadioButton from "@/Components/Buttons/RadioButton.vue";
import LeftRightCheckbox from "@/Components/Buttons/LeftRightCheckbox.vue";
import SolarUtils from '@/Mixins/SolarUtils';

import VueMarkdown from '@/Components/vue-markdown.vue';

export default {
  name: "Dashboard",
  components: {
    DayBilanz,
    SeasonWheel,
    AkkuWidget,
    Checkbox,
    RadioButton,
    LeftRightCheckbox,
    VueMarkdown
  },
  mixins: [UISectionMixin, SolarUtils],
  props: {
    Name: {
      type: String,
      default: ViewMode.dashboard,
    },
  },
  data() {
    return {
      Time,
      Season,
      Car,
      Pump,
      Shadow,
      Fascade,
      GreenRoof,
      PVSize,
      HouseType,
      Area,
      Battery,
      PVSize,
      Time,
      TimeShort,
      OperatorConcept,
      pageContent: null,
    };
  },
  watch: {
    "$store.state.area.season": function(newSeason) {
      this.$store.state.audio.controller.PlaySound("season." + newSeason, true);
    },
    "$store.state.world.partialLoading": function(bool, oldValue) {
      if (!bool && oldValue && this.$store.state.area.current != null) {
        console.log("SetActiveScene in Dashboard");
        //this.$store.state.world.mainScene.xr.SceneController.SetActiveScene(this.$store.state.area.current.defaultRoom);
        //this.$store.state.world.mainScene.xr.CSSSceneController.SetActiveScene(this.$store.state.area.current.defaultRoom);
        //this.$store.state.audio.controller.PlaySound("season." + this.$store.state.area.season,true);
      }
    },
  },
  computed: {
    GetEinstrahlung() {
      var index = Utility.CalculateSolarIndex(
        this.$store.state.area.sunPosition
      );
      if (
        this.$store.state.area.current.solarDaten == null ||
        !this.$store.state.area.current.solarDaten[index].hasOwnProperty(
          "Einstrahlung"
        )
      ) {
        return 0;
      }
      return this.$store.state.area.current.solarDaten[
        index
      ].Einstrahlung.toFixed(2);
    },
    GetEnergieWechselrichter() {
      if (this.$store.state.area.current.solarDaten == null) {
        return 0;
      }

      var index = Utility.CalculateSolarIndex(
        this.$store.state.area.sunPosition
      );

      return this.$store.state.area.current.solarDaten[
        index
      ].EnergieWechselrichter.toFixed(2);
    },
    GetGesamtertrag() {
      if (this.$store.state.area.current.solarDaten == null) {
        return 0;
      }

      var amount = 0;
      this.$store.state.area.current.solarDaten.map(
        (data) => (amount += data.EnergieWechselrichter)
      );
      return Math.floor(amount); //this.$store.state.area.solarDaten.reduce((a,b) => {return a.EnergieWechselrichter + b.EnergieWechselrichter});
    },
  },
  mounted() {
    this.pageContent = this.$store.state.content.dashboardData;

    this.OnUISectionMounted();

    if (this.$store.state.audio.controller != null) {
      this.$store.state.audio.controller.PlaySound(
        "season." + this.$store.state.area.season,
        true
      );
    }
  },
  methods: {
    ChangeSetting(name, value) {
      this.$store.commit("area/ChangeGlobalSettings", {
        setting: name,
        data: value ? Car.Car : Car.NoCar,
      });
    },

    SwitchBilanz() {
      this.$store.commit("area/SetSubBilanz");
    },
  },
};
</script>

<style lang="scss" scoped>
.global-settings {
  margin-top: 1rem;
}
.page-wrapper {
  height: 100%;
}

.Dashboard .card-board {
  font-size: 12px;

  position: absolute;
  width: 20%;
  bottom: 4.7rem;
  padding: 1rem;
  right: 0;
}

.season-wheel-inner {
  width: 130%;
  left: -15%;
  position: relative;
}

.card-board-horizontal {
  position: absolute;
  display: flex;
  bottom: 0;
  padding-bottom: 1rem;
  width: calc(80% - 180px - 1rem);
  left: 190px;

  h2 {
    margin-bottom: 0.5rem;
  }
}

.note * {
  color: #a4a5a8;
}

.card-info-row {
  margin-bottom: 1rem;

  .orange{
    color :$orange!important;
  }

  &.special {
    h3 {
      font-size: 12px;
      line-height: 1rem;
      color: $orange;
    }

    span,
    sup {
      color: #9b9ea7;
    }
  }
}
.subtitle {
  color: $orange;
  text-align: center;
  margin-top: 3px;
}

.wheel {
  width: 100%;
  max-width: 400px;
  position: relative;
}
.legende {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 2px;
  .item {
    margin-right: 1rem;
    //color: white;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 10px;
  }
  .dot {
    width: 10px;
    height: 10px;
    margin-right: 0.15rem;
    border-radius: $border-radius-4;
  }
  .ueberschuss {
    background-color: $orange;
  }
  .nutzung {
    background-color: #ffcd9e;
  }
  .einkauf {
    background-color: #31c5f4;
  }
}

.card-board {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 2rem;

  h2 {
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .card {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.card-board-inner {
  display: flex;
  margin-bottom: 1rem;
}

.legend-item {
  margin-right: 2rem;
  font-weight: 500;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.legend-item-color {
  width: 30px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 0 15px 15px 0;
  box-shadow: inset 5px 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.radio-item {
  input[type="radio"] {
    display: none;
  }
  label {
    padding-left: 33px;
    display: block;
    position: relative;
    height: 33px;
    white-space: nowrap;
    line-height: 30px;
    color: #5b5b5b;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      border: 2px solid #fff;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 50%;
      position: absolute;
      background: #c2c2c2;
    }

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      left: 4px;
      top: 4px;
      bottom: 0;
      border-radius: 50%;
      position: absolute;
      background: transparent;
      transform: scale(0);
      transition-duration: 0.2s;
    }
  }

  input[type="radio"]:checked + label {
    &:before {
      background: #f9c697;
    }
    &:after {
      width: 20px;
      height: 20px;
      transform: scale(1);
      background: $orange;
      transition-duration: 0.2s;
    }
  }
}

.quote .selected {
  color: $orange;
  font-weight: 700;
}

.border-bottom {
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
}

.card-info-row.special {
  h3.disabled,
  label.disabled,
  label.disabled.selected {
    color: #ccc;
    font-weight: normal;
  }
}

@media screen and (max-width: 1200px), screen and (max-height: 800px) {
  .Dashboard {
    .card-board {
      width: 250px;
    }
    .card-board-inner{
      display: block;
    }
    .width-10 {
      width: 100%;
    }
    .card-board-horizontal {
      flex-direction: column;
      width: calc(100% - 250px - 180px - 1rem);
    }

    .fixed-card-small {
      position: fixed;
      left: 1rem;
      width: 155px;
      bottom: 215px;
      height: initial;

      .flex {
        flex-direction: column;
      }
    }
    .border-bottom ,.card-info-row{
      margin-bottom: 0.5rem;
    }
  }
}
</style>
<style lang="scss">
.ExplorerSingle .card-board,
.ExplorerSingle .card-board-horizontal {
  display: none;
}
</style>
<style lang="scss" scoped>
input[type="range"].range-slider-style {
  width: 100%;
  margin: 6.5px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"].range-slider-style:focus {
  outline: none;
}
input[type="range"].range-slider-style::-webkit-slider-runnable-track {
  background: #f9c697;
  border: 0;
  border-radius: 15px;
  width: 100%;
  height: 15px;
  cursor: pointer;

  .disabled & {
    background: #eee;
  }
}
input[type="range"].range-slider-style::-webkit-slider-thumb {
  margin-top: -6.5px;
  width: 28px;
  height: 28px;
  background: #f38d2f;
  border: 2px solid #ffffff;
  border-radius: 15px;
  cursor: pointer;
  -webkit-appearance: none;

  .disabled & {
    background: #eee;
  }
}
input[type="range"].range-slider-style:focus::-webkit-slider-runnable-track {
  background: #fad3af;
}
input[type="range"].range-slider-style::-moz-range-track {
  background: #f9c697;
  border: 0;
  border-radius: 15px;
  width: 100%;
  height: 15px;
  cursor: pointer;
}
input[type="range"].range-slider-style::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background: #f38d2f;
  border: 2px solid #ffffff;
  border-radius: 15px;
  cursor: pointer;
}
input[type="range"].range-slider-style::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 7.5px 0;
  color: transparent;
  width: 100%;
  height: 15px;
  cursor: pointer;
}
input[type="range"].range-slider-style::-ms-fill-lower {
  background: #f8b97f;
  border: 0;
  border-radius: 30px;
}
input[type="range"].range-slider-style::-ms-fill-upper {
  background: #f9c697;
  border: 0;
  border-radius: 30px;
}
input[type="range"].range-slider-style::-ms-thumb {
  width: 28px;
  height: 28px;
  background: #f38d2f;
  border: 2px solid #ffffff;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"].range-slider-style:focus::-ms-fill-lower {
  background: #f9c697;
}
input[type="range"].range-slider-style:focus::-ms-fill-upper {
  background: #fad3af;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"].range-slider-style {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
</style>
