<template>
<div id="battery-icon" >
  <svg viewBox="-10 0 164 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#F38D2F" d="M11.7345 9.42858L11.7345 20.6032L2.29294 20.6032C0.94415 20.6032 -2.4208e-06 21.5344 -2.36376e-06 22.582L-1.22941e-06 43.418C-1.16604e-06 44.582 1.07903 45.3968 2.29294 45.3968L11.7345 45.3968L11.7345 56.5714C11.7345 61.8095 16.5901 66 22.6596 66L117.075 66C123.144 66 128 61.6931 128 56.5714L128 9.42857C128 4.19048 123.144 3.30755e-06 117.075 3.52056e-06L22.6596 6.83414e-06C16.5901 7.04715e-06 11.7345 4.19048 11.7345 9.42858ZM75.9368 20.0212L75.9368 26.3069L98.1918 26.3069C101.024 26.3069 102.103 29.4497 99.8103 30.7302L69.058 48.5397C66.765 49.8201 63.7977 48.4233 63.7977 46.0952L63.7977 39.8095L41.5427 39.8095C38.7102 39.8095 37.6312 36.6667 39.9241 35.3862L70.5416 17.4603C72.8346 16.1799 75.9368 17.5767 75.9368 20.0212Z"
    />
  </svg>
</div>
</template>

<script>
export default {
  name : "Battery",
  props : ["batteryState"]
}  
</script>