<template>
  <canvas class="bilanz-canvas" ref="bilanz"></canvas>
</template>

<script>
import { Area, Season, Time, TimeShort } from "../../Enum/Enums";
import SolarUtils from "../../Mixins/SolarUtils";
import TWEEN from "@tweenjs/tween.js";

export default {
  name: "DayBilanz",
  mixins: [SolarUtils],
  data() {
    return {
      c: null,
      size: {
        x: 0,
        y: 0,
      },
      solarDaten: null,
      solarIndex: 0,
      offsetHeight : this.$route.params.xrMode == "VR" ? 30 : 0,
      dpr: window.devicePixelRatio ? window.devicePixelRatio : 1,
      skalierung:
        this.$store.state.area.current.name == Area.FamilyHouse ? 18 : 100,
      skalierungFixed:
        this.$store.state.area.current.name == Area.FamilyHouse ? 18 : 100,
    };
  },
  watch: {
    "$store.state.area.season": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.battery": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.batteryState": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.houseType": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.orientation": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.pvSize": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.shadow": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.sunPosition": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.fascade": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.greenRoof": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.operatorConcept": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.current.solarDaten": function() {
      this.UpdateSolarData();
    },
    "$store.state.area.showSubBilanz": function() {
      this.ChangeScale();
      this.UpdateSolarData();
    },
  },
  mounted() {
    this.UpdateSolarData();

    this.c = this.$refs.bilanz.getContext("2d");

    this.$store.commit("SetBilanzElement", this.$refs.bilanz);

    this.HandleResize();

    this.AnimationLoop = this.AnimationLoop.bind(this);
    this.HandleResize = this.HandleResize.bind(this);

    this.$store.state.world.mainScene.xr.Events.addEventListener(
      "OnAnimationLoop",
      this.AnimationLoop
    );

    window.addEventListener("resize", this.HandleResize);

    this.$router.beforeEach((to, from, next) => {
      if (to.name != "Dashboard") {
        this.Unmount();
      }

      next();
    });
  },
  methods: {
    Unmount() {
      this.$store.state.world.mainScene.xr.Events.removeEventListener(
        "OnAnimationLoop",
        this.AnimationLoop
      );
      window.removeEventListener("resize", this.HandleResize);
    },
    HandleResize() {
      if (typeof this.$refs.bilanz == "undefined") {
        return;
      }

      var parentSize = this.$refs.bilanz.parentNode.getBoundingClientRect();
      var width = parentSize.width - 32;

      var heightFactor = this.$route.params.xrMode == "VR" ? .5 : .3;
      var height = width * heightFactor;

      var maxHeight = this.$route.params.xrMode == "VR" ? 400 : 150;

      height = height > maxHeight ? maxHeight : height;
      height = height < 100 ? 100 : height;

      this.$refs.bilanz.height = height;

      this.size.x = width;
      this.size.y = height;

      this.$refs.bilanz.width = this.size.x * this.dpr;
      this.$refs.bilanz.height = (this.size.y + this.offsetHeight) * this.dpr;

      this.$refs.bilanz.style.width = this.size.x + "px";
      this.$refs.bilanz.style.height = (this.size.y + this.offsetHeight)+ "px";

      this.c.scale(this.dpr, this.dpr);
    },
    ChangeScale() {
      var oldScale = this.skalierung;
      var newScale = 8;

      this.seasonTween = new TWEEN.Tween({
        scale: oldScale,
      })
        .to(
          {
            scale: newScale,
          },
          1000
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate((v) => {
          this.skalierungFixed = v.scale.toFixed(0);
          this.skalierung = v.scale;
        })
        .onComplete(() => {})
        .start();
    },
    UpdateSolarData() {
      if (this.$store.state.area.current.solarDaten == null) {
        return;
      }

      this.solarIndex = this.GetSolarIndex;

      var original = [...this.$store.state.area.current.solarDaten];

      if (this.$store.state.area.showSubBilanz) {
        const mieter = 24;

        original = original.map((dataset) => {
          var newData = Object.assign({}, dataset);
          newData.Netzbezug = newData.Netzbezug / mieter;
          newData.Eigenverbrauch = newData.Eigenverbrauch / mieter;
          newData.Netzeinspeisung = newData.Netzeinspeisung / mieter;
          return newData;
        });
      }

      this.TweenSolarDaten(original);
    },

    FlattenDataObj(data) {
      var result = {};
      Object.keys(data).map((index) => {
        Object.keys(data[index]).map((keyName) => {
          var newKey = index + "?" + keyName;
          result[newKey] = data[index][keyName];
        });
      });
      return result;
    },

    NestDataObj(data) {
      var result = [];
      Object.keys(data).map((longKey) => {
        var index = parseInt(longKey.split("?")[0]);
        if (result.length < index + 1) {
          result.push({});
        }
        var name = longKey.split("?")[1];
        result[index][name] = data[longKey];
      });
      return result;
    },

    TweenSolarDaten(newData) {
      if (this.solarDaten == null) {
        this.solarDaten = newData;
        return;
      }

      const oldData = this.FlattenDataObj(this.solarDaten);
      newData = this.FlattenDataObj(newData);

      this.seasonTween = new TWEEN.Tween({
        data: oldData,
      })
        .to(
          {
            data: newData,
          },
          1000
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate((v) => {
          const nestedData = this.NestDataObj(v.data);
          this.solarDaten = nestedData;
        })
        .onComplete(() => {})
        .start();
    },

    AnimationLoop() {
      if (this.c == null) {
        return;
      }

      if(this.$route.params.xrMode == "VR"){

        this.c.fillStyle = "#fff";
        this.c.fillRect(0, 0, this.size.x, this.size.y + this.offsetHeight);
      }else{
        this.c.clearRect(0, 0, this.size.x, this.size.y + this.offsetHeight);
      }

      const maxSize = 1;
      const stepCount = this.$store.state.area.current.name == Area.FamilyHouse ? 2 : 13;
      const halfWayStep = (this.size.x / 2 * maxSize) / stepCount;
      const factor = this.$store.state.area.current.name == Area.FamilyHouse ? 10 : 1;
      const center = (this.size.x / 2);
      // Lines behind and Text
      this.c.strokeStyle = "#eee";
      this.c.fillStyle = "#ccc";
      this.c.textAlign = "center";
      this.c.font = "normal 10px 'Inter', sans-serif";
      this.c.beginPath();
      for(var i=0;i < Math.floor(stepCount * factor);i++){
        let distX  = (halfWayStep * i) / factor;
        
        //Left
        this.c.moveTo(center - distX, 0);
        this.c.lineTo(center - distX, this.size.y - 10);
        
        this.c.stroke();
        
        //right
        this.c.moveTo(center + distX, 0);
        this.c.lineTo(center + distX, this.size.y - 10);
        
        this.c.stroke();

        if(this.$store.state.area.current.name == Area.FamilyHouse && i % 2 != 0){continue;}

        if(i != 0){
          this.c.fillText(((10 / factor) * i), center - distX, this.size.y - 1);
        }
        this.c.fillText(((10 / factor) * i), center + distX, this.size.y - 1);
      }
      this.c.closePath();



      // Draw Balken

      var height = (this.size.y - 10) / 6;
      var balkenHeight = height * 0.9;
      var margin = height - balkenHeight;

      //backgroundBalken
      this.c.fillStyle = "#eee";
      this.c.fillRect(
        0,
        balkenHeight * this.solarIndex + margin * this.solarIndex - 1,
        this.size.x,
        balkenHeight + margin * 2
      );

      if (this.solarDaten == null) {
        return;
      }

      this.solarDaten.map((d, index) => {
        //console.log(this.solarDaten);

        var { Netzeinspeisung, Netzbezug, Eigenverbrauch, Verbrauch } = d;

        //calculate data
        var xEigenverbrauch = center / stepCount * Eigenverbrauch * .1;
        var xNetzbezug = center / stepCount * Netzbezug * .1;
        var xNetzeinspeisung = center / stepCount * Netzeinspeisung * .1;

        //Eigenverbrauch
        this.c.fillStyle = this.colors.lightOrange;
        var roundedCorner = xNetzbezug < 5;

        if (xEigenverbrauch > 0.1) {
          this.roundedRect(
            center - xEigenverbrauch,
            index * height + margin / 2,
            xEigenverbrauch,
            balkenHeight,
            5,
            roundedCorner,
            false,
            false,
            roundedCorner
          );
        }

        //Netzbezug
        this.c.fillStyle = this.colors.lightBlue;
        if (xNetzbezug > 5) {
          this.roundedRect(
            center - xEigenverbrauch - xNetzbezug,
            index * height + margin / 2,
            xNetzbezug,
            balkenHeight,
            5,
            true,
            false,
            false,
            true
          );
        }

        //Netzeinspeisung
        this.c.fillStyle = this.colors.orange;
        if (xNetzeinspeisung > 5) {
          this.roundedRect(
            center,
            index * height + margin / 2,
            xNetzeinspeisung,
            balkenHeight,
            5,
            false,
            true,
            true,
            false
          );
        }
      });

    
      //Beschriftung
      this.c.textAlign = "left";
      this.c.font = "bold 10px 'Inter', sans-serif";

      for(var i=0;i<this.$store.state.area.current.solarDaten.length;i++){
        if(this.solarIndex == i){
          this.c.fillStyle = "#000000";
          this.c.font = "bold 10px 'Inter', sans-serif";
        }else{
          this.c.fillStyle = "#cccccc";
          this.c.font = "normal 10px 'Inter', sans-serif";
        }
        
        
        this.c.fillText(
          TimeShort[i].toUpperCase(),
          5,
          balkenHeight * i + margin * i - 1 + 16
        );
      }


      if(this.$route.params.xrMode =="VR"){
        
        this.c.fillStyle = this.colors.orange;
        this.c.textAlign = "center";
        this.c.font = "normal 10px 'Inter', sans-serif";
        this.c.fillText("in kWh", center,this.size.y + 10);

        //Legende
        this.c.textAlign = "left";
        
        this.c.fillStyle = this.colors.lightBlue;
        this.c.fillRect(center - 150,this.size.y + 15 , 10 , 10);
        this.c.fillStyle = "#000";
        this.c.fillText("Stromeinkauf", center - 135,this.size.y + 23);

        this.c.fillStyle = this.colors.lightOrange;
        this.c.fillRect(center - 60,this.size.y + 15 , 10 , 10);
        this.c.fillStyle = "#000";
        this.c.fillText("Stromnutzung", center - 45,this.size.y + 23);

        this.c.fillStyle = this.colors.orange;
        this.c.fillRect(center + 35,this.size.y + 15 , 10 , 10);
        this.c.fillStyle = "#000";
        this.c.fillText("Stromüberschuss", center + 50,this.size.y + 23);
      }


    },
  },
};
</script>

<style lang="scss" scoped>
.bilanz-canvas {
  width: initial;
  height: initial;
  position: relative;
  margin-top: 0.5rem;
  margin-right: 0;
}
</style>
