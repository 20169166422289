<template>
  <div>

    <VueMarkdown
      v-if="CheckIfShouldRender"
      class="article-content-inner"
      :source="this.$store.state.content.currentSubTab.subtabtext_component"
    ></VueMarkdown>

    <ToggleSubTab />



  </div>
</template>


<script>
import AnimationPlayer from "../../Components/AnimationPlayer";
import ToggleSubTab from './ToggleSubTab.vue';
import config from '../../../main.config';
import VueMarkdown from '@/Components/vue-markdown.vue';

export default {
  name: "SubTab",
  props: {},
  components: {
    AnimationPlayer,
    ToggleSubTab,
    VueMarkdown
  },
  watch: {},
  mounted() {
    console.log("SUBTAB MOUNTED ", this.$store.state.content.currentSubTab);

    let pos = this.$store.state.content.currentSubTab.subtabPosition;
    let target = this.$store.state.content.currentSubTab.subtabTarget;

    //SetPosition and Target
    // this.$store.state.world.mainScene.xr.Controls.SetPosition(pos.x,pos.y,pos.z);
    // this.$store.state.world.mainScene.xr.Controls.SetTarget(target.x,target.y,target.z);

    console.log("pois", "SET POSITION",   this.$store.state.content.poi);


  },
  destroyed(){
    console.log("SUBTAB DESTROYED ", this.$store.state.content.currentSubTab)
  },
  computed: {
    CheckIfShouldRender(){
      if(this.$store.state.content.currentSubTab.StoreBooleanSettings == null){return true;}
      if(this.$store.state.content.currentSubTab.StoreBooleanSettings.length == 0){return true;}
      if(this.$store.state.content.currentSubTab.StoreBooleanSettings.length > 0){
        return this.$store.state.content.currentSubTab.StoreBooleanSettings.filter((reference)=>{
          return this.$store.state.area[reference.Key].includes('No');
        }).length == 0;

      }
    },
    CheckTabState() {
      if (!this.$store.state.content.currentPOI.UIBehaviour.ConfiguratorDependent) {
        return true;
      }
      //Konfigurator anhängig aber den richtigen Konfigurator Einstellungen entsprechen
      return (
        this.$store.state.content.currentPOI.UIBehaviour.StoreValue ==
        this.$store.state.area[this.$store.state.content.currentPOI.UIBehaviour.StoreKey]
      );
    },
    GetVisibility(){
      //console.log("GetVisiblity",this.$store.state.content.currentPOI.UIBehaviour, this.$store.state.content.currentSubTab)
    }
  },
  methods: {
    
  },
};
</script>

<style>
.article-enable {
  padding: 1rem 0;
}

.article-setting {
  background: #F38D2F;
  padding: 1rem;
  border-radius: 10px;
  color:#fff;
}


.article-setting p:last-child {
  margin-bottom: 0;
}

.article-setting input{
  display: none;
  position: absolute;
}


.article-setting p {
  color: #fff;
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.8rem;
  margin-bottom: .5rem;
}

.article-setting label{
  padding-left: 50px;
  line-height: 1.2rem;
  display: block;
  position: relative;
  color: #fff;
  cursor: pointer;
}

.article-setting label:before{
  content:'';
  margin: auto;
  display:block;
  width:40px;
  height:40px;
  background:#fff;
  border-radius:10px;
  box-shadow:inset 0 0 2px;
  position:absolute;
  left:0;
  top:0;
  bottom:0;
}
.article-setting input[type="checkbox"]:checked + label:after {
  content: "";
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
  bottom: 0;
  margin: auto;
  background: #F9C697;
  border-radius: 5px;

}


</style>