<template>
  <div :class="'no-prevention article-tab tab-active-' + GetActiveState +' '+GetActivationClass">
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Strommast">
      <Strommast class="icon" :state="GetIconState" />
    </template>
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Batterie">
      <Batterie class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.EAuto">
      <EAuto class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.EAutoFalse">
      <EAutoFalse class="icon" :state="GetIconState" />
    </template>

    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Solarmodul">
      <Solarmodul class="icon" :state="GetIconState" />
    </template>

    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Modulmontage">
      <ModulmontageIcon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.ModulmontageBalkon">
      <ModulmontageBalkonIcon class="icon" :state="GetIconState" />
    </template>

    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Verschattung">
      <Verschattung class="icon" :state="GetIconState" />
    </template>

    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Ausrichtung">
      <Ausrichtung class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Kueche">
      <Kueche class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Pump">
      <Pump class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Betriebsstrom">
      <Betriebsstrom class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Balkon">
      <Balkon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Messkonzept">
      <MesskonzeptIcon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Betreiberkonzept">
      <BetreiberkonzeptIcon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Beteiligungsquote">
      <BeteiligungsquoteIcon class="icon" :state="GetIconState" />
    </template>

    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Solarpflicht">
      <SolarpflichtIcon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.Eigentum">
      <EigentumIcon class="icon" :state="GetIconState" />
    </template>
    
    <template v-if="this.article.IconSVG.SVGTitle == SVGIcon.MieterstromInitiieren">
      <MieterstromInitiieren class="icon" :state="GetIconState" />
    </template>

    <router-link
      @click.native="PlaySound"
      :to="'/desktop/'+this.$router.currentRoute.params.area+'/explore/' + article.id"
    />

  </div>
</template>

<script>
import mainConfig from "../../../main.config";
import Strommast from "../../SVG/Article/DGSZentrum_IconSet_Netz_Icon";
import Batterie from "../../SVG/Article/DGSZentrum_IconSet_PvGeräte_Icon";
//import EAuto from "../../SVG/Configurator/DGSZentrum_IconSet_EAutoTrue_Icon";
import Solarmodul from "../../SVG/Article/DGSZentrum_IconSet_PvModule_Icon";
import ModulmontageIcon from "../../SVG/Article/DGSZentrum_IconSet_Modulmontage_icon";
import ModulmontageBalkonIcon from "../../SVG/Article/DGSZentrum_IconSet_Modulmontagebalkon_icon";


import Verschattung from "../../SVG/Article/DGSZentrum_IconSet_Shadow_Icon";
import Kueche from "../../SVG/Article/DGSZentrum_IconSet_Alltagsverbrauch_Icon";
import { SVGIcon } from "../../Enum/Enums";

import EAuto from '../../SVG/Article/DGSZentrum_IconSet_EAutoTrue_Icon';
import EAutoFalse from '../../SVG/Configurator/DGSZentrum_IconSet_EAutoFalse_Icon';

import Ausrichtung from "../../SVG/Article/DGSZentrum_IconSet_Kompass_Icon";
import Pump from "../../SVG/Configurator/DGSZentrum_IconSet_PumpTrue";
import Betriebsstrom from "../../SVG/Article/DGSZentrum_IconSet_Betriebsstrom_Icon.vue";
import Balkon from "../../SVG/Article/DGSZentrum_IconSet_Balkon_Icon.vue";

import MesskonzeptIcon from '../../SVG/Article/DGSZentrum_IconSet_MesskonzeptIcon_Icon';
import BetreiberkonzeptIcon from '../../SVG/Article/DGSZentrum_IconSet_Betreiberkonzept_Icon';
import BeteiligungsquoteIcon from '../../SVG/Article/DGSZentrum_IconSet_Beteiligungsquote_Icon';
import SolarpflichtIcon from '../../SVG/Article/DGSZentrum_IconSet_Solarpflicht_Icon';
import EigentumIcon from '../../SVG/Article/DGSZentrum_IconSet_Eigentum_Icon';

import MieterstromInitiieren from '../../SVG/Article/DGSZentrum_IconSet_MieterstromInitiieren_Icon.vue';


export default {
  name: "ArticleTab",
  props: {
    article: null,
  },
  components: {
    Strommast,
    Batterie,
    EAuto,
    Solarmodul,
    Verschattung,
    EAutoFalse,
    Kueche,
    ModulmontageIcon,
    ModulmontageBalkonIcon,
    Ausrichtung,
    Pump,
    Balkon,
    Betriebsstrom,
    MesskonzeptIcon,
    BetreiberkonzeptIcon,
    BeteiligungsquoteIcon,
    SolarpflichtIcon,
    EigentumIcon,
    MieterstromInitiieren
  },
  data() {
    return {
      SVGIcon,
    };
  },
  watch: {},
  mounted() {

  },
  computed: {
    GetActiveState: function () {
      var state = false;
      if (this.$store.state.content.currentPOI != null) {
        state = this.article.id == this.$store.state.content.currentPOI.id;
      }
      return state;
    },
    GetIconState: function () {
      if (this.GetActiveState) {
        return 1;
      } else if (this.$parent.CheckTabState(this.article)) {
        return 0;
      } else {
        return -1;
      }
    },
    GetActivationClass: function () {
      return this.$parent.CheckTabState(this.article) ?  'enabled':'disabled';
    },
  },
  methods: {
    PlaySound(){
      this.$store.state.audio.controller.PlaySound("menu.click");
    },
    CheckEnum(name) {
      return this.article.IconSVG.SVGTitle == this.SVGIcons[name];
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.icon {
  width: 40px;
  height: 40px;
}
</style>